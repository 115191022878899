.img-hmd {
  margin-left: -40px;
}
.p-t-92 {
  padding-top: 92px;
}
.mt-hmd {
  margin-top: 15px;
}
span.icon-menu {
  font-size: 40px;
}

.login-hmd {
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;
}

.login-hmd:hover {
  color: #fff;
}

.owl-carousel-promote .owl-item img {
  max-width: 100% !important;
  height: 100%;
  max-height: 450px;
}

.owl-carousel-promote.owl-carousel .owl-nav button.owl-prev,
.owl-carousel-promote.owl-carousel .owl-nav button.owl-next {
  background-color: #fff;
  border-radius: 100px;
}

.owl-carousel-promote.owl-carousel .owl-nav button.owl-prev:after {
  top: -8px;
  left: -1px;
}

.owl-carousel-promote.owl-carousel .owl-nav button.owl-next:after {
  top: -8px;
  left: 2px;
}

.banContainer-hmd {
  /* padding: 85px 0 0 0; */
  line-height: 0;
  position: relative;
  min-height: 318px;
  background: transparent;
  max-height: 396px;
}

.banContainer-hmd .slick-prev,
.banContainer-hmd .slick-next {
  z-index: 1;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.banContainer-hmd .slick-prev {
  left: 30px;
}

.banContainer-hmd .slick-next {
  right: 30px;
}

.banContainer-hmd .slick-prev:before,
.banContainer-hmd .slick-next:before {
  font-size: 25px;
  color: #111;
  display: none;
}
.banContainer-hmd .slick-dots {
  bottom: 15px;
}
.banContainer-hmd .slick-dots li {
  width: 12px;
  height: 12px;
}
.banContainer-hmd .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  color: #fff;
  background-color: #999;
}
.banContainer-hmd .slick-dots li.slick-active button {
  background-color: #fff;
}
.banContainer-hmd .slick-dots li button:before {
  font-size: 1rem;
  color: #fff;
  top: -3px !important;
  left: -4px !important;
}

.banContainer-hmd .item {
  padding: 8px 4px;
  background-color: transparent;
  position: relative;
  display: block;
}

.banContainer-hmd .item img {
  border-radius: 8px;
  min-height: 302px;
  max-height: 302px;
  background-color: #eee;
}

.banContainer-hmd .item img {
  width: 100%;
}

/*.slick-slide, .slick-list, .slick-slider, .slick-track {
	height: 200px !important;
	 min-height: 100px;
    max-height: 100px;
}*/
.collection-hmd {
  background: linear-gradient(to right, #403c3c, #4d9219, #403c3c);
  padding: 15px 20px;
  margin: 10px;
  border-radius: 5px;
}
/*.owl_1.owl-carousel .owl-stage-outer {
	    overflow: visible;
}*/
.collection-hmd .owl_1 li {
  text-align: center;
  position: relative;
  top: 1px;
}
.collection-hmd .owl_1 li a {
  color: #fffbfbb8;
}
.collection-hmd .owl_1 li.active a {
  color: #fff;
}

.collection-hmd .owl-theme .owl-nav .owl-prev {
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  background-color: #efe7e7;
  color: transparent;
  border-radius: 100px;
}
.collection-hmd .owl-theme .owl-nav .owl-prev:before {
  font-family: FontAwesome;
  content: "\f104";
  font-size: 19px;
  opacity: 1;
  color: #222;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.collection-hmd .owl-theme .owl-nav .owl-next:before {
  font-family: FontAwesome;
  content: "\f105";
  font-size: 19px;
  opacity: 1;
  color: #222;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0%);
}
.collection-hmd .owl-theme .owl-nav .owl-next {
  right: 0;
  top: 0;
  height: 30px;
  width: 30px;
  background-color: #efe7e7;
  color: transparent;
  border-radius: 100px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0px;
}
.fw-bold {
  font-weight: bold;
}

.fw-unbold {
  font-weight: normal;
}
.headingContainer img {
  width: 200px;
}
.ot-stock-hmd {
  position: absolute;
  width: 91%;
  height: 92%;
  top: 0;
  z-index: 1;
  left: 14px;
}
/*.stock-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #bbb;
    opacity: 0.5;
    z-index: 1;
    left: 0;
}*/
.dis-off-hmd {
  position: absolute;
  z-index: 0;
  background-color: #4caf50;
  color: #fff;
  left: -15px;
  padding: 3px 7px;
  transform: rotate(-47deg);
  top: 4px;
  border-radius: 5px;
}
.opacity-hmd {
  opacity: 0.7;
}
.stock-p-hmd {
  position: relative;
  height: 100%;
}
.ot-stock-hmd h5 {
  position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  z-index: 9999;
  font-weight: bold;
  background-color: rgb(204 204 204 / 0.8);
  width: 100%;
  text-align: center;
  padding: 10px;
}
/*--collection--*/
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: -4px;
  z-index: 2;
  background-color: #f5f5f6;
}
.sticky .inverse-bar {
  padding-top: 0;
  padding-bottom: 0;
}

.card-btm {
  height: 100%;
  background-color: transparent;
  border: 0 none;
}
.sticky_cart {
  position: -webkit-sticky;
  position: sticky;
  top: 66px;
  z-index: 0;
  margin-top: 33px;
}
.mb-0 {
  margin-bottom: 0;
}
.p-t-60 {
  padding-top: 60px;
}
.p-0 {
  padding: 0;
}
.h-40 {
  height: 40px;
}
.inverse-bar .navbar-brand {
  color: #222;
  font-weight: 600;
  font-size: 29px;
}
.bb-1 {
  border-bottom: 1px solid #a9a9b3;
}
.empty {
  border: 1px solid #ccc8c8;
  padding: 4px;
  margin: 0px 0 15px;
  border-radius: 7px;
}
.js-menu-toggle {
  color: #fff;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 18px;
}
.site-mobile-menu-body {
  padding-top: 25px !important;
}

.fill-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 14px;
}
.fill-card ul li {
  padding-left: 20px !important;
  position: relative;
  padding-top: 0;
  margin-bottom: 15px;
}
.fill-card ul li:last-child {
  padding-left: 0px !important;
  padding-right: 0;
}
.fill-card ul li span.isIconI {
  position: absolute;
  left: 0;
  top: 5px;
}
.theme_red_clr {
  color: #f00;
}
.couponCreditContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0px;
  width: 100%;
}
.applyCouponBtn.disableCredits,
.applyCouponBtn:active.disableCredits,
.applyCouponBtn:focus.disableCredits,
.applyCouponBtn:hover.disableCredits,
.applyCouponBtn:visited.disableCredits {
  color: rgba(45, 40, 46, 0.4);
  cursor: not-allowed;
  pointer-events: none;
  border-color: rgba(45, 40, 46, 0.4);
  background: rgb(255, 255, 255);
}
.applyCouponBtn,
.applyCouponBtn:active,
.applyCouponBtn:focus,
.applyCouponBtn:hover,
.applyCouponBtn:visited {
  display: block;
  color: rgb(45, 40, 46);
  font-weight: 400;
  font-size: 0.875rem;
  flex-grow: 1;
  position: relative;
  padding: 0.625rem 0.25rem;
  border-width: 0.0625rem;
  border-style: dashed;
  border-color: rgb(45, 40, 46);
  border-image: initial;
}
/*.quantity-hmd {
    position: relative;
    display: flex;
    float: right;
}*/
.quantity-hmd button {
  width: 22px;
  height: 27px;
  position: relative;
  top: 0px;
  border: 0px solid #ccc;
  background-color: transparent;
  padding: 0;
}
.quantity-hmd button:focus,
.quantity-hmd input:focus {
  outline: 0px auto -webkit-focus-ring-color;
}
.quantity-hmd button span {
  font-size: 23px;
  color: #f00;
  /*    position: absolute;
    top: 2px;
    right: 0;*/
}
/*.quantity button img.plus-cart {
	position: absolute;
    top: 27%;
    left: 27%;
}
.quantity button img.minus-cart {
	position: absolute;
    top: 10px;
    left: 27%;
}*/
.quantity-hmd input[type="text"] {
  text-align: center;
  width: 31px;
  height: 25px;
  border: 0 none;
  position: relative;
  line-height: 23px;
  background-color: transparent;
  left: 0px;
  /*top: -2px;*/
  top: -5px;
}
.card-bar {
  z-index: 1;
  position: relative;
  top: 0%;
  background-color: #fff;
  width: 92%;
  left: 50%;
  transform: translate(-50%, -22px);
  padding: 10px 15px;
}
.isVegIcon {
  color: green;
}

.isNonVegIcon {
  color: red;
}
.card-bar h5.card-title {
  font-size: 17px;
  margin-bottom: 24px;
}
.btn-add-hmd {
  background-color: transparent;
  border-radius: 6px;
  padding: 3px 14px 5px;
  color: #ff0000;
  font-weight: 500;
  border: 1px solid #ff0000;
}
.btn-add-hmd:hover {
  color: #fff;
  background-color: #ff0000;
  border: 1px solid transparent;
}

/*.height-card {
	    height: 295px;
}*/

footer {
  padding: 15px 0 0;
  text-align: center;
  background: linear-gradient(to right, #403c3c, #4d9219, #403c3c);
}
aside.footer-social-wrap {
  padding-top: 10px;
  text-align: left;
}
span.td-social-icon-wrap {
  margin: 0 10px;
}
span.td-social-icon-wrap a {
  color: #fff;
}
.menu-footer-menu-container {
  text-align: right;
}
footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
}
.menu-footer-menu-container ul li a {
  color: #fff;
}
.sub_footer {
  margin-top: 20px;
  box-shadow: -3px -9px 12px -9px #0c0c0c;
}
.sub_footer p {
  margin: 0;
  padding: 7px 0;
  color: #fff;
  font-size: 12px;
}
.d-none-xl {
  display: none !important;
}

/*-----Description css start-------*/

.productDetailInnerContainer-hmd {
  background: hsla(0, 0%, 100%, 0.8);
  -webkit-box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  padding: 1.875rem;
  margin-top: 20px;
}
.backToCollections-hmd {
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  font-size: 0.8125rem;
}
.backToCollectionsIcon-hmd {
  padding: 0 0.5rem 0 0;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.linkPrimary,
.linkPrimary:active,
.linkPrimary:focus,
.linkPrimary:hover,
.linkPrimary:visited {
  color: #2d282e;
}
.productDetailImageContainer-hmd {
  position: relative;
}
.singleProductCollectionName-hmd {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.3125rem;
  font-weight: 400;
  margin: 0;
  color: #323232;
}
.singleProductName-hmd {
  font-size: 2.1875rem;
  color: #323232;
}
.singleProductDesc-hmd {
  color: #4d4d4d;
  font-weight: 300;
  margin: 0.9375rem 0;
}
.isdescp-hmd {
  margin: 4px 9px 0 0;
  font-size: 18px;
}
.productDetailImageContainer-hmd img {
  width: 100%;
}
.searchAddBtn-hmd {
  padding: 0.475rem 1.125rem 0.275rem;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  line-height: inherit;
  background: #ff0000;
  border-radius: 4px;
  border: 1px solid #ff0000;
  color: #fff;
}
.searchAddBtn-hmd:hover,
.backToCollectionsLink-hmd:hover {
  color: #fff;
}
.backToCollectionsLink-hmd {
  margin-top: 9px;
  padding: 0.375rem 0.5rem 0.275rem;
  border: 0.0625rem solid #ff0000;
  display: inline-block;
  background-color: #ff0000;
  border-radius: 0.3125rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  line-height: inherit;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.spiceLevel-hmd img {
  width: 35px;
}
.newProductBtn-hmd {
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  color: #fff;
  font-size: 0.625rem;
  line-height: 1;
  font-weight: 500;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#64c08a),
    color-stop(#00a550),
    to(#00914c)
  );
  background: -webkit-linear-gradient(right, #64c08a, #00a550, #00914c);
  background: -o-linear-gradient(right, #64c08a, #00a550, #00914c);
  background: linear-gradient(270deg, #64c08a, #00a550, #00914c);
  background-size: 600% 600%;
  -webkit-animation: slideGradient 4s ease infinite;
  animation: slideGradient 4s ease infinite;
}
.shareProductLinksWrapper-hmd {
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  margin-top: 0.3125rem;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.shareProductLink-hmd {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.shareProductIcon-hmd,
.shareProductIcon-hmd:hover,
.shareProductIcon-hmd:focus {
  padding: 0.375rem 0.75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3125rem;
  color: #fff;
}
.shareProductIcon-hmd.shareFb-hmd,
.shareProductIcon-hmd.shareFb-hmd:hover,
.shareProductIcon-hmd.shareFb-hmd:focus {
  background: #3a5696;
}
.shareProductIcon-hmd.shareTwitter-hmd,
.shareProductIcon-hmd.shareTwitter-hmd:hover,
.shareProductIcon-hmd.shareTwitter-hmd:focus {
  background: #51a3de;
  margin: 0 0.625rem;
}
.shareProductIcon-hmd.copyLink-hmd,
.shareProductIcon-hmd.copyLink-hmd:hover,
.shareProductIcon-hmd.copyLink-hmd:focus {
  background-color: #847e7e;
}
.singleProductAddedToCart-hmd {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.625rem 1.25rem;
  background-color: hsla(36, 43%, 91%, 0.8);
  color: #2d282e;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
}

/*---order--*/
.orderDetailContainer-hmd {
  /* -webkit-box-shadow: 0 0 10px 0 hsla(0,0%,55%,.21);
    box-shadow: 0 0 10px 0 hsla(0,0%,55%,.21);*/
  background-color: #fff;
  margin-top: 20px;
  padding: 15px 10px 0;
  position: relative;
}
.accordion .orderDetailContainer-hmd:after {
  font-family: "FontAwesome";
  content: "\f0d7";
  position: absolute;
  top: 66px;
  right: 30px;
  font-size: 25px;
  color: #ff0000;
}
.accordion .orderDetailContainer-hmd.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f0d8";
  position: absolute;
  top: 66px;
  right: 30px;
  font-size: 25px;
  color: #ff0000;
}
.spaceBtn-hmd {
  background-color: #ff0000;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  border: 0.0625rem solid #ff0000;
  border-radius: 0.3125rem;
  padding: 4px 14px;
  font-size: 15px;
}
.pageHeading {
  font-size: 1.5rem;
  font-family: Exo\2, sans-serif;
  padding: 1.875rem 0 0;
  line-height: 1;
}
.orderSummarySection-hmd {
  display: inline-block;
  width: 100%;
}
.orderSummarySection-hmd:first-child {
  border-bottom: 1px solid #dcdcdc;
}
.headingContainer {
  display: inline-block;
  font-size: 1.5rem;
}
.orderLeft-hmd {
  float: left;
  width: 70%;
}
.orderRight-hmd {
  float: right;
  width: 30%;
  text-align: right;
}
.summaryOrderId-hmd,
.summaryOrderTime-hmd {
  font-size: 15px;
  color: #5e5e5e;
}
.summaryOrderAmount {
  font-size: 1rem;
  margin: 0;
  padding: 0 1.875rem;
  font-weight: 500;
  position: relative;
  color: #8a8a8a;
}
.cardCollapse {
  background-color: #fff;
  padding-top: 0;
}

.orderDataProducts-hmd {
  display: inline-block;
  width: 100%;
  background-color: #eee;
  padding: 20px;
  border-radius: 6px;
}
.orderDataProducts-hmd .summaryOrderId-hmd {
  margin-bottom: 0;
  color: #0a0a0a;
}
.orderDataProducts-hmd .summaryOrderAmount {
  padding: 0;
  color: #0a0a0a;
}
.orderDataPrices-hmd {
  display: inline-block;
  width: 100%;
  padding: 20px 20px 0;
}
.orderDataPrices-hmd .orderLeft-hmd {
  width: 60%;
  padding-bottom: 15px;
}
.orderDataPrices-hmd .summaryOrderId-hmd,
.orderDataPrices-hmd .summaryOrderAmount {
  color: #0a0a0a;
  padding-right: 0;
}
.orderDataPrices-hmd .orderRight-hmd {
  float: right;
  width: 40%;
  text-align: right;
}
.orderDataOtherDetails-hmd {
  padding-top: 0.9375rem;
  border-top: 0.0625rem solid #dcdcdc;
}
.orderDataOtherDetailsHeading-hmd {
  font-size: 0.875rem;
}
.orderDataAddressSection-hmd {
  font-size: 13px;
  font-weight: 500;
}
.iconStar span {
  font-size: 22px;
}
.star-fill {
  color: #ff0000;
}

.progressbar {
  counter-reset: step;
  padding: 0;
  display: inline-block;
  width: 100%;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #b5b1b1;
  z-index: 9999;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 27px;
  border: 2px solid #b5b1b1;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 3px;
  content: "";
  position: absolute;
  background-color: #b5b1b1;
  top: 14px;
  left: -45%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #000;
}
.progressbar li.active:before {
  border-color: #000;
  background: #4b861d;
  color: #fff;
}
.progressbar li.active + li:after {
  background-color: #000;
}

.address-card-hmd {
  box-shadow: rgba(186, 186, 186, 0.25) 0px 10px 15px 0px;
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
.addNewAddressBox-hmd {
  justify-content: center;
  align-items: center;
  border-width: 0.0625rem;
  border-style: dashed;
  border-color: rgb(220, 220, 220);
  border-image: initial;
  border-radius: 0.3125rem;
  text-align: center;
  padding: 20px;
  height: 100%;
  min-height: 150px;
  display: grid;
}
.addAddressHeading-hmd {
  color: rgb(67, 67, 67);
  font-size: 0.875rem;
  letter-spacing: 0.1875rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0.875rem 0px 0;
}

.addressBox-hmd {
  justify-content: left;
  align-items: center;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: rgb(220, 220, 220);
  border-image: initial;
  border-radius: 0.3125rem;
  text-align: left;
  padding: 20px;
  height: 100%;
  min-height: 150px;
  display: grid;
}
span.btnAdress {
  margin-right: 10px;
}
.blck_btn {
  background: rgb(52, 50, 53);
  color: #fff;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: transparent;
  padding: 6px 18px;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 8px 0px;
}
.blck_btn:hover {
  color: #fff;
}
.yl_btn {
  background: #ff0000;
  color: #fff;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #ff0000;
  padding: 6px 18px;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 8px 0px;
}
.yl_btn:hover {
  color: #fff;
}
.p-2-23 {
  padding: 3px 23px;
}
.ghostBtn-hmd {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.375rem 1.125rem;
  border-width: 0.0625rem;
  border-style: dashed;
  border-color: rgb(45, 40, 46);
  border-image: initial;
  color: #777;
}
.ghostBtn-hmd:hover {
  color: #000;
}

.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow-y: scroll;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
  pointer-events: auto;
}
.modal-window > div {
  width: 650px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 3%);
  transform: translate(-50%, 3%);
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px #111;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  font-size: 20px;
  position: absolute;
  right: -40px;
  text-align: center;
  top: -40px;
  width: 40px;
  text-decoration: none;
  background-color: #000;
  border-radius: 100px;
  height: 40px;
  line-height: 35px;
  color: #fff;
  border: 2px solid rgb(181 33 33);
  font-weight: 600;
}
.modal-inner {
  text-align: center;
}
.modal-outer,
.modal-inner {
  -webkit-border-image: -webkit-linear-gradient(
      left,
      rgb(181 33 33),
      rgb(181 33 33)
    )
    20 fill stretch;
  border-style: solid;
  border-image: linear-gradient(90deg, rgb(181 33 33) 0px, rgb(181 33 33)) 20 /
    1 / 0 stretch;
  margin: 0.6rem;
  border-radius: 0px !important;
  position: relative;
}
.modal-close:hover {
  color: rgb(181 33 33);
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

h4.bdr-b {
  padding: 15px 20px 0;
}
h4.bdr-b img {
  width: 100%;
  max-width: 200px;
}
.field {
  padding: 0 40px;
}
.pll-0 {
  padding: 0 0 10px 0 !important;
}
.modal-window form label {
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
}
.modal-window form input,
.modal-window form textarea {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid rgb(220, 220, 220);
  padding: 6px;
  margin-bottom: 5px;
  background-color: rgb(250, 250, 250);
}
.modal-window form textarea {
  margin-bottom: 0px;
}

.btn-prt {
  width: 33.33%;
  float: left;
  margin-bottom: 0 !important;
  border-right: 1px solid #ccc;
}
.btn-prt:last-child {
  border-right: 0px solid #ccc;
}
.btn-prt a {
  width: 100%;
  font-weight: normal;
  border-radius: 0;
  color: #999;
  border: 0 none !important;
}
.btn-prt a.active {
  background-color: rgb(255 0 0) !important;
  border-radius: 0px;
  color: #fff !important;
}
.w-modal {
  width: 450px !important;
  transform: translate(-50%, 30%) !important;
}
.wbtn-hmd {
  width: 40%;
}
.border-rgt-hmd {
  border-right: 1px solid #dcdcdc;
}

.creditsImgContainer-hmd {
  height: 100%;
  padding: 0.9375rem;
  background-color: #211d1c;
}
.creditsImgContainer-hmd img {
  width: 100%;
}

.ash-Toppadding-hmd {
  padding-top: 86px;
}
.creditRightContainer-hmd {
  padding: 1.25rem 3.125rem;
  text-align: center;
}
.description-hmd {
  margin-bottom: 1.875rem;
}
.currentCredits-hmd {
  background-color: #f2eade;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0.9375rem 1.25rem;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
}
.creditsCalc-hmd {
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
}
.pd-x-hmd {
  padding-left: 0;
}
.pd-btm-hmd {
  padding-bottom: 40px;
}
.term-hmd {
  padding: 90px 0 40px;
}
.collpse_term .panel-default {
  border: 1px solid #4d911a;
  margin-bottom: 20px;

  border-radius: 5px;
}
.collpse_term .panel-default h4 {
  margin-bottom: 0;
  padding: 12px 20px;
}
.collpse_term .panel-default h4 a {
  color: #4d911a;
}
.collpse_term .panel-default h4 i {
  color: #4d911a;
}
.collpse_term .panel-default .panel-body {
  padding: 0px 20px 12px;
  line-height: 24px;
}
.seeMore_btn_hmd {
  font-weight: bold;
  color: #4d911a;
}
.hg1-hmd ul {
  margin-bottom: 10px;
}
.hg1-hmd ul li {
  line-height: 22px;
}
.hg1-hmd span p {
  margin-bottom: -4px;
}

.contact-pad-hmd {
  padding: 90px 0 40px;
}
.theme_btn {
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;
}
.addrs i {
  color: #f00;
}

/*----------slick slider start---------------*/
.slider-about {
  width: 100%;
}
.slider-about .slick-slider .slick-list.draggable {
  padding: 7px 0 !important;
}
.slider-about .slick-slide {
  margin: 0px 5px;
}

.slider-about .slick-slide img {
  width: 100%;
}

.slider-about .slick-prev,
.slider-about .slick-next {
  z-index: 1;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.slider-about .slick-prev:before,
.slider-about .slick-next:before {
  font-size: 25px;
  color: #111;
}

.slider-about .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.slider-about .slick-active {
  opacity: 1;
}

.slider-about .slick-current {
  opacity: 1;
}
.slider-about button.slick-disabled {
  display: none !important;
}
.pad-spac-hmd,
.pad-spacss-hmd {
  padding-left: 0;
}
.slider-about .article {
  color: #111;
  position: relative;
  background: transparent;
  margin-right: 10px;
  overflow: hidden;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -o-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.box_shadow:hover {
  box-shadow: 0px 0px 14px 7px #f7871e;
}
.slider-about .article:hover:before {
  content: "";
  width: 100%;
  height: 40%;
  border-radius: 2px;
  -webkit-background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.8)
  );
  -moz-background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.8)
  );
  -o-background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.8)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.8)
  );
}
.slider-about .service {
  text-align: left;
  padding: 5px 11px;
  color: #f7871e;
  font-size: 25px !important;
  text-transform: capitalize;
  font-weight: normal;
}

.slider-about .slick-next {
  right: 9px;
  z-index: 1;
  background-color: #0000004f !important;
}
.slider-about .slick-prev {
  left: 9px;
  z-index: 1;
  background-color: #0000004f !important;
}
.slider-about .slick-next:before {
  color: #fff !important;
}
.slider-about .slick-prev:before {
  color: #fff !important;
}

/*----------slick slider end---------------*/

.addr-pad {
  margin: 30px 0;
}

.addr-pad .card {
  height: 100%;
}
.descp-stock-hmd {
  width: 100%;
  height: 100%;
  left: 0;
}
.descp-stock-hmd h5 {
  top: 50%;
}

.carouselHeading-hmd {
  color: #434343;
  font-size: 0.875rem;
  margin: 0.875rem 0;
  letter-spacing: 0.1875rem;
  font-weight: 500;
}
.productName-hmd {
  margin-bottom: 0.375rem;
  line-height: 1.3;
  white-space: pre-wrap;
  color: #1d1d1d;
  font-size: 1rem;
  min-height: 2.5rem;
}
.card-product-hmd {
  padding: 15px 10px;
}
.card-img-hmd {
  height: 100%;
}
.add-hmd {
  line-height: 17px;
  padding: 5px 15px;
  border: 1px solid #ff0000;
  background-color: transparent;
  color: #ff0000;
}
.add-hmd:hover {
  border: 1px solid transparent;
  background-color: #ff0000;
  color: #fff;
}
.center_checkout .slick-prev {
  left: -15px;
  background-color: #fff !important;
  box-shadow: -1px 0px 2px 1px #bdb8b8;
}
.center_checkout .slick-next {
  right: -15px;
  background-color: #fff !important;
  box-shadow: 1px 0px 2px 1px #bdb8b8;
}
.center_checkout .slick-prev:before,
.center_checkout .slick-next:before {
  color: #111 !important;
}
.checkoutDetail-hmd {
  margin: 0;
  list-style-type: none;
}
.checkoutDetail-hmd .detail-hmd {
  position: relative;
}
.checkoutDetail-hmd .detail-hmd:before {
  position: absolute;
  display: block;
  content: "";
  width: 0.0625rem;
  height: 100%;
  border-left: 1px dashed #979797;
  left: -0.9375rem;
  top: 1.875rem;
}
.checkoutDetail-hmd .detail-hmd:last-child:before {
  display: none;
  content: "";
}
.checkoutDetail-hmd .stepBtn-hmd.activated {
  background: #2d282e;
  color: #fff;
}
.checkoutDetail-hmd .stepBtn-hmd {
  display: inline-block;
  background: #fff;
  color: #2d282e;
  padding: 0.625rem 1.375rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  position: relative;
  left: -40px;
  top: 20px;
  z-index: 1;
}
.activated .checkoutSectionIcon-hmd {
  color: #ff0000;
}
.checkoutDetail-hmd .stepBtn-hmd .heading-hmd {
  margin-left: 0.75rem;
  font-weight: 500;
  font-size: 0.875rem;
}
.checkoutDetail-hmd .checkoutSectionChecked-hmd {
  border: 0.0625rem solid #ff0000;
}
.loginSuccessContainer-hmd {
  padding: 2.5rem 1.25rem 1.25rem;
  position: relative;
  background: #fff;
}
.loginSuccessContainer-hmd span.checkedIconFont-hmd:before {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 1.25rem;
}
.icon-check_circle:before {
  color: #2fb700;
}
.loginSuccessContainer-hmd .userDetails-hmd {
  font-size: 1rem;
  font-weight: 500;
  color: #434343;
  text-transform: capitalize;
}
.checkoutContainer-hmd {
  padding: 92px 0 0 0;
}
.chooseAddressContainer-hmd {
  padding: 2.5rem 1.25rem 1.25rem;
  background: #fff;
  border: 0.0625rem solid hsla(0, 0%, 4%, 0.3);
}
.checkoutContainer-hmd .desc {
  font-size: 1rem;
  line-height: 1.4;
  color: #1d1d1d;
}
.chooseAddressContainer-hmd .addressContainer-hmd {
  padding: 0;
}

.loginContainer-hmd {
  color: #434343;
  padding: 3.5rem 1.25rem 1.25rem;
  background: #fff;
  border: 0.0625rem solid hsla(0, 0%, 4%, 0.3);
}
.countryCodeContainer-hmd {
  position: relative;
}
.form-hmd {
  background-color: #eee !important;
  height: 40px;
}
.columnSeparator-hmd {
  display: block;
  position: relative;
  text-align: center;
  height: 100%;
}
.columnSeparator-hmd:before {
  position: absolute;
  content: "";
  width: 0.0625rem;
  height: 100%;
  background: #dcdcdc;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}
.columnSeparator-hmd .separator-hmd {
  display: inline-block;
  border: 0.0625rem solid #dcdcdc;
  background: #fff;
  border-radius: 50%;
  padding: 0.625rem;
  position: relative;
  font-weight: 500;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.registerlink {
  font-size: 0.8125rem;
  font-weight: 500;
}
.darkLinkBtn {
  color: #8b0314;
  text-decoration: underline;
}
.deliverHereWrapper-hmd {
  border: none;
  padding: 1.5rem 1.25rem 1.25rem;
  position: relative;
  background: #fff;
}
.otp {
  background-color: #fff;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-align: left;
  font-weight: 500;
  font-size: 0.875rem;
}
.displayMessage {
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
  color: #37b34a;
}
.primaryBtn {
  padding: 0.375rem 1.125rem;
  border: 0.0625rem solid #ff0000;
  display: inline-block;
  background-color: #ff0000;
  border-radius: 0.3125rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  line-height: inherit;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
}
.paymentModeWrapper {
  padding: 2.5rem 1.25rem 1.25rem;
  background: #fff;
  display: flex;
  border: 0.0625rem solid hsla(0, 0%, 4%, 0.3);
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #ccc;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #f7f7f7;
  border-color: transparent !important;
}
.nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  background-color: transparent;
  color: #111;
}
.checkout-content > .active {
  display: block;
  min-height: 165px;
}
.nav.nav-tabs {
  float: left;
  display: block;
  border-bottom: 0;
  border-right: 1px solid #eee;
  width: 30%;
}
.tab-content {
  width: 100%;
}
li.change {
  display: inline;
  margin-right: 48px;
}
.paymentModes-hmd {
  margin-left: 2.5rem;
  width: 80%;
}
.paymentModes-hmd .content {
  margin: 1.25rem 0;
}
.cardError,
.subText {
  margin-bottom: 0.9375rem;
}
.selectWalletList-hmd {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.walletName {
  border: 0.0625rem solid #dcdcdc;
  border-radius: 0.25rem;
  padding: 0.9375rem;
  margin: 1.25rem 0;
  display: block;
}
.walletOption {
  width: 100%;
  margin: 0 !important;
}
.paymentOffer {
  color: #54617a;
  font-size: 0.75rem;
  margin-top: 0.3125rem;
  line-height: 1.25;
}
.cartProductNameInner-hmd {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
  font-weight: 600;
  font-size: 0.8125rem;
  color: #555;
  width: 110px;
}
.cartProductNameInner-hmd .isIconI {
  top: 2px !important;
  font-size: 15px;
}
/*.qrt-hmd {
    margin-left: -14px;
}*/
.qrt-hmd .quantity-hmd button {
  width: 14px;
  height: 21px;
}
.width-hmd {
  width: 100px;
}
/*.qrt-hmd .quantity-hmd {
    top: -18px;
}*/
.gst-hmd .qrt-hmd .quantity-hmd {
  top: -12px;
}

span.price-hmd {
  position: relative;
  top: 0;
  font-weight: bold;
  right: -7px;
}
.qrt-hmd .quantity-hmd button span {
  font-size: 22px;
}
.qrt-hmd .quantity-hmd input[type="text"] {
  width: 25px;
  height: 24px;
}
.flush-card li.pl-li {
  padding-left: 0px !important;
}
.whatsAppContainer {
  background: #54965e;
  border-radius: 0.3125rem;
  margin-top: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 10px 15px 0 hsla(0, 0%, 73%, 0.25);
  box-shadow: 0 10px 15px 0 hsla(0, 0%, 73%, 0.25);
}
.coverContainer {
  background-image: url(https://assets.faasos.io/behrouzbiryani-react/production/Mobile_Border.svg);
  background-size: 98% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px;
  padding-left: 10px;
  font-family: diavlo, sans-serif;
}
.coverContainer .textContainer {
  font-size: 11px;
  padding: 8px;
}
.coverContainer .textContainer .title {
  font-size: 12px;
  font-weight: 600;
}
.coverContainer .radioLabel {
  margin: auto;
  margin-right: 10px;
  color: #fff;
  font-size: 2rem;
}
.coverContainer .customisationIcon {
  color: #fff;
  margin-left: 0.0625rem;
}
.mtop-5 {
  margin-top: 3rem;
}
.cardAnchor {
  display: block;
}
.categ_title {
  font-weight: bold;
  color: #ff0000;
}
.form-wrapper-2 {
  width: 450px;
  padding: 15px;
  border-radius: 10px;
  margin: 0 auto;
}
.form-wrapper-2 input {
  width: 310px;
  height: 40px;
  padding: 10px 5px;
  float: left;
  border: 0;
  background: #eee;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.form-wrapper-2 button {
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 110px;
  color: #fff;
  text-transform: uppercase;
  background: #ff0000;
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.mobile-hmd {
  top: 40px;
}
.mobile-hmd:before {
  width: 96%;
  height: 1px;
  background: #dcdcdc;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 18px;
}

span.couponName {
  color: rgb(102, 102, 102);
  min-width: 8.125rem;
  display: inline-block;
  text-align: center;
  border-width: 0.0625rem;
  border-style: dashed;
  border-color: rgb(102, 102, 102);
  border-image: initial;
  padding: 0.3125rem 0.9375rem;
  margin-left: 10px;
}
.couponDetails {
  padding-left: 26px;
}

.cptnOffer-hmd {
  padding: 0 30px;
}
.offerList-hmd {
  list-style: none;
  padding: 0;
}
.btn-pref {
  display: inline-block !important;
  width: 100% !important;
  border: 1px solid #ccc;
  float: none !important;
  border: 1px solid #ccc !important;
}
.coupon {
  text-align: left;
}
.clse_mdl_btn {
  right: -16px;
  top: -18px;
  z-index: 1;
}

.hg1-hmd b {
  float: left;
}
.hg1-hmd span {
  display: block;
  margin-left: 143px;
}
.diabled {
  cursor: not-allowed !important;
}

@media (max-width: 1199px) {
  .width-hmd {
    width: 84px !important;
  }
  /*.height-card {
	    height: 250px;
	}*/
  /*.card-bar {
		transform: translate(-50%, 50px);
	}*/
  .img-hmd {
    margin-left: 0px;
  }
  .progressbar li:after {
    left: -43%;
  }
  .isdescp-hmd {
    margin: 4px 0px 0 0;
  }
  .paymentModes-hmd {
    margin-left: 1.5rem;
    width: 90%;
  }
  .pad-spac-hmd {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .mtop-5 {
    margin-top: 1.5rem;
  }
  /* .banContainer-hmd .slick-dots {
    bottom: 15px;
  } */
  .banContainer-hmd .item img {
    min-height: 229px;
    max-height: 229px;
  }
  .banContainer-hmd {
    max-height: 254px;
    min-height: 254px;
  }
  .productDetailImageContainer-hmd {
    height: inherit;
  }
  .mb-hmd {
    margin-bottom: 15px;
  }
  .mt-hmd {
    margin-top: 0;
  }
  .progressbar li:after {
    left: -41%;
  }
  .pd-x-hmd {
    padding-right: 0;
  }
  .addr-pad:nth-child(3) {
    margin: 0;
  }
  .columnSeparator-hmd {
    height: 3px;
  }
  .columnSeparator-hmd:before {
    width: 100%;
    height: 1px;
    top: 0;
  }
  .loginContainer-hmd {
    padding: 1.5rem 1.25rem 1.25rem;
  }
  .checkoutDetail-hmd {
    padding: 0;
  }
  .checkoutDetail-hmd .detail-hmd:before {
    display: none;
  }
  .checkoutDetail-hmd .detail-hmd {
    margin: 25px 0 0;
  }
  .checkoutDetail-hmd .stepBtn-hmd.activated {
    top: -8px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .fill-card {
    border: 0.0625rem solid hsla(0, 0%, 4%, 0.3);
  }
  .cartProductNameInner-hmd {
    font-size: 14px;
    width: 300px;
  }
  .cartProductNameInner-hmd .isIconI {
    top: 3px !important;
    font-size: 15px;
  }
  .paymentModes-hmd {
    width: 94%;
  }
  .deliverHereWrapper-hmd {
    padding-top: 1.125rem;
  }
  .chooseAddressContainer-hmd {
    padding: 1.5rem 1.25rem 1.25rem;
  }
  .paymentModeWrapper {
    padding: 1.5rem 1.25rem 1.25rem;
  }
  .pad-spac-hmd {
    padding-left: 15px;
  }
  .qrt-hmd .quantity-hmd,
  span.price-hmd {
    top: 0;
  }
}

@media (max-width: 767px) {
  .addr-pad {
    margin: 15px 0 0;
  }
  .addr-pad:nth-child(3) {
    margin: 15px 0 0;
  }
  /*.height-card {
	    height: 270px;
	}*/
  .collection-hmd {
    margin: 10px 0 0;
  }
  .banContainer-hmd .slick-dots {
    bottom: 15px;
  }

  .banContainer-hmd .item img {
    min-height:140px;
    max-height: 140px;
  }
  .banContainer-hmd {
    /* padding: 68px 0 0 0; */
    max-height: 160px;
    min-height: 160px;
  }

  .hidden-xs {
    display: none;
  }
  .proceedToCartContent {
    position: fixed;
    z-index: 99999;
    width: 100%;
    background-color: #ff0000;
    bottom: 0;
    left: 0;
    padding: 10px;
  }
  .proceedToCartContent .viewCartLeft {
    float: left;
    font-size: 20px;
    color: #fff;
  }
  .viewCartRight {
    float: right;
  }
  .viewCartRight .view-cart {
    color: #fff;
    font-size: 18px;
    margin-top: 2px;
    display: block;
  }
  .view-cart i {
    font-size: 23px;
    position: relative;
    top: 4px;
  }
  .d-show-xl {
    display: block !important;
  }
  .productDetailImageContainer-hmd {
    margin-bottom: 10px;
  }
  .singleProductCollectionName-hmd {
    margin: 1.25rem 0 0.3125rem;
    font-size: 0.87rem;
  }
  .modal-window > div {
    width: 350px;
  }
  .field {
    padding: 0 10px;
  }
  .modal-close {
    right: -30px;
    top: -30px;
  }
  .wbtn-hmd {
    width: 50%;
  }
  .w-modal {
    width: 350px !important;
  }
  .progressbar li:after {
    left: -36%;
  }
  .ash-Toppadding-hmd {
    padding-top: 66px;
  }
  .creditRightContainer-hmd {
    padding: 1.25rem 0.125rem;
    text-align: center;
  }
  .position-relative .logo-cont-hmd {
    text-align: center;
  }
  .card-product-hmd {
    padding: 15px 20px;
  }
}

@media (max-width: 544px) {
  .slider-about {
    padding: 0 10px;
  }
  .log-hmd {
    padding: 0;
  }
  .login-hmd {
    margin-right: 11px;
    padding: 6px 12px 10px;
  }
  .site-navbar {
    padding: 0;
  }
  .inverse-bar .navbar-brand {
    font-size: 22px;
  }
  .banContainer-hmd .item img {
    min-height: 80px;
    max-height: 80px;
  }
  .banContainer-hmd {
    /* padding: 68px 0 0 0; */
    max-height: 108px;
    min-height: 106px;
  }
  /*.card-bar {
	    transform: translate(0%, 0px);
	    width: 100%;
	    position: initial;
	}*/
  .card-bar h5.card-title {
    font-size: 21px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  .card-bar h6 {
    font-weight: 600;
    font-size: 20px;
  }
  .sticky .inverse-bar {
    padding: 0;
  }
  .inverse-bar .navbar-form {
    width: 100%;
    margin-bottom: 10px;
  }
  /*.height-card {
		height: 100%;
		padding: 0;
	}*/
  .collection-hmd {
    margin: 0px 0 0;
    padding: 8px 9px;
  }
  .singleProductName-hmd {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .orderSummarySection-hmd .summaryOrderAmount {
    padding: 0;
    text-align: left;
  }
  .orderDataPrices-hmd {
    padding: 20px 0px 0;
  }
  .orderData-hmd {
    border-right: 0 none;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .productDetailInnerContainer-hmd {
    padding: 8px 13px;
  }
  .singleProductDesc-hmd {
    color: #4d4d4d;
    font-weight: 300;
    margin: 0.625rem 0;
    line-height: 20px;
    font-size: 0.87rem;
  }
  .ash-Toppadding-hmd {
    padding-top: 66px;
  }
  .creditRightContainer-hmd {
    padding: 1.25rem 0.125rem;
  }
  .searchAddBtn-hmd {
    padding: 0.475rem 0.3rem 0.275rem;
  }
  .paymentModeWrapper {
    display: block;
  }
  .nav.nav-tabs {
    float: none;
    border-right: 0px solid #eee;
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .nav-tabs .nav-item {
    float: left;
    width: 50%;
  }
  .paymentModes-hmd {
    margin-left: 0;
    width: 100%;
  }
  .paymentModes-hmd .content {
    margin: 10px 0;
  }
  .cardError,
  .subText {
    margin-bottom: 7px;
  }
  .cartProductNameInner-hmd {
    width: 116px;
  }
  .cartProductNameInner-hmd .isIconI {
    top: 2px !important;
  }
}

@media (max-width: 332px) {
  .accordion .orderDetailContainer-hmd:after,
  .accordion .orderDetailContainer-hmd.collapsed:after {
    top: 80px;
    right: 25px;
  }
  .progressbar li:after {
    left: -31%;
  }
}
