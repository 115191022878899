* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "NunitoSans500";
  src: url(./fonts/NunitoSans/NunitoSans500.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "NunitoSans600";
  src: url(./fonts/NunitoSans/NunitoSans600.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "NunitoSans800";
  src: url(./fonts/NunitoSans/NunitoSans800.ttf) format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "NunitoSans900";
  src: url(./fonts/NunitoSans/NunitoSans900.ttf) format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "NunitoSans900Italic";
  src: url(./fonts/NunitoSans/NunitoSans900Italic.ttf) format("truetype");
  font-style: italic;
  font-weight: 900;
}

@viewport {
  orientation: portrait;
}

body {
  background-color: #f0f0f0;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #364d59 !important;
  font-weight: 300;
  font-size: 1rem;
}

#hmd {
  background-color: #f0f0f0;
}

.slick-next::before,
.slick-prev::before {
  content: none;
  display: none;
}

.shine {
  background: #e0e0e0;
  background-image: linear-gradient(
    to right,
    #dfe0e2 0%,
    #c6c7c9 20%,
    #dfe0e0 40%,
    #ebebeb 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.stars {
  display: flex;
  flex-direction: row;
  min-width: 85px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background: transparent;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  display: none;
  background: transparent;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #2f6aaf;
  border-radius: 8px;
}

::-webkit-scrollbar {
  display: none;
}
