*:focus {
  outline: 0;
  outline: none;
}

.slick-about {
  /* max-height: 400px; */
  width: 100%;
  position: relative;
  /* display: block; */
  overflow: scroll;
}
.slick-about .slick-list .slick-track .slick-slide {
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  outline: none;
  /* height: 100%; */
}

.slick-about .slick-list .slick-track .slick-slide:focus {
  outline: none;
}

.slick-about .slick-list .slick-track .slick-slide .about-item {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
  min-height: 400px;
  /* max-height: 480px; */
}

.slick-about .slick-list .slick-track .slick-slide .about-item .bottom {
  margin: 20px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  color: rgb(20, 20, 20);
}
