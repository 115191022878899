.anna {
  height: 500px !important;
}

@media screen and (max-width: 458px) {
  .cards {
    display: flex !important;
    flex-direction: column !important;
    /* width: 100% !important; */
    flex: 1;
  }
}

@media screen and (max-width: 991px) and (min-width: 458px) {
  .cards {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.cards div {
  /* width: 100% !important; */
}

.shimmer-thumbnail {
  margin-bottom: 0px !important;
}
